<template>
  <div class="roleInfo">
    <a-page-header title="返回" @back="() => $router.go(-1)" />
    <div class="actionBar">
      <a-input-search
        size="large"
        v-model="listParams.keyword"
        placeholder="请输入关键词搜索"
        enter-button="查询"
        style="width: 350px"
        allowClear
        @search="onSearch"
      />
      <a-button
        icon="plus"
        type="primary"
        @click="showModal"
        size="large"
        v-if="handLists.top.indexOf('add') != -1"
      >
        添加调研题目</a-button
      >
    </div>
    <a-modal
      :title="modalTitle"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
      okText="确定"
      cancelText="取消"
      :width="960"
    >
      <a-form
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 16 }"
        :from="addFromData"
      >
        <a-form-item label="题目标题">
          <a-input v-model="addFromData.question_title" />
        </a-form-item>
        <a-form-item label="题目类型">
          <a-radio-group
            v-model="addFromData.question_type"
            :disabled="typeFlag"
          >
            <a-radio-button :value="0">
              单选
            </a-radio-button>
            <a-radio-button :value="1">
              多选
            </a-radio-button>
            <a-radio-button :value="2">
              问答
            </a-radio-button>
          </a-radio-group>
        </a-form-item>

        <a-form-item label="选项" v-if="addFromData.question_type != 2">
          
          <a-space
            v-for="(item, index) in addFromData.survey_option"
            :key="index"
            size="small"
            style="margin-bottom:10px"
          >
            <span> {{ index + 1 }}. </span>
            <a-textarea
              v-model="addFromData.survey_option[index]"
              style="width:360px"
              :auto-size="{ minRows: 2, maxRows: 6 }"
            />
            <span>
              权重:
              <a-input-number
                :min="0"
                :max="100"
                v-model="addFromData.survey_ratio[index]"
                style="width:80px"
              />
              <span>%</span>
            </span>
            <a-button type="link" @click="delOption(index)">
              删除
            </a-button>
          </a-space>
          <div>
              温馨提示：权重之和在0-100之间！
          </div>
          <a-button
            type="dashed"
            style="margin:10px 0 0 15px"
            @click="addOption()"
          >
            添加选项
          </a-button>
        </a-form-item>
      </a-form>
    </a-modal>
    <a-table
      :columns="columns"
      :data-source="data"
      rowKey="id"
      :pagination="{
        total: count,
        current: listParams.page,
        pageSize: listParams.limit,
        showTotal: (total) => `共 ${total} 条`,
      }"
      @change="pageChange"
    >
      <span slot="index" slot-scope="text, record, index">
        {{ index }}
      </span>
      <span slot="action" slot-scope="text, record">
        <span v-if="handLists.right.indexOf('edit') != -1">
          <FontIcon
            @click.native="editMenu(record)"
            title="修改"
            type="icon-xiugai"
            size="22"
          ></FontIcon>
          <a-divider type="vertical" />
        </span>
        <a-popconfirm
          title="确定删除？"
          ok-text="确定"
          cancel-text="取消"
          @confirm="confirm(record)"
          v-if="handLists.right.indexOf('del') != -1"
        >
          <FontIcon title="删除" type="icon-lajitong" size="22"></FontIcon>
        </a-popconfirm>
      </span>
    </a-table>
  </div>
</template>

<script>
import {
  surveyQuestionSave,
  surveyQuestionList,
  surveyQuestionDel,
} from "@/apis/index";
const columns = [
  {
    title: "ID",
    dataIndex: "id",
    scopedSlots: { customRender: "id" },
  },
  {
    title: "题目",
    dataIndex: "question_title",
    scopedSlots: { customRender: "question_title" },
  },
  {
    title: "题目类型",
    dataIndex: "question_type",
    scopedSlots: { customRender: "question_type" },
    customRender: (text, record) => {
      switch (text) {
        case 0:
          return "单选";
        case 1:
          return "多选";
        default:
          return "问答";
      }
    },
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
  },
];

export default {
  data() {
    return {
      columns,
      data: [],
      visible: false,
      confirmLoading: false,
      modalTitle: "",
      addFromData: {},
      listParams: {
        page: 1,
        limit: 10,
        keyword: "",
        survey_id: this.$route.query.id,
      },
      count: 0,

      typeFlag: false,
      handLists: this.$store.state.menuHandleList,
    };
  },
  created() {
    this.getSurveyQuestionList();
  },
  computed: {
    handList() {
      return this.$store.state.menuHandleList;
    },
  },
  watch: {
    handList(val) {
      this.handLists.top = val.top ? val.top : [];
      this.handLists.right = val.right ? val.right : [];
    },
  },
  methods: {
    delOption(index) {
      if (index == 0) {
        return this.$message.error("至少保留一个选项");
      }
      this.addFromData.survey_option.splice(index, 1);
    },
    addOption() {
      this.addFromData.survey_option.push("");
      this.addFromData.survey_ratio.push(1);
    },
    showModal() {
      this.modalTitle = "新增题目";
      this.typeFlag = false;
      (this.addFromData = {
        id: "",
        survey_id: this.$route.query.id,
        question_title: "",
        question_type: 0,
        survey_option: [""],
        survey_ratio: [1],
      }),
        (this.visible = true);
    },
    handleOk(e) {
      if (this.addFromData.question_type == 2) {
        this.confirmLoading = true;
        surveyQuestionSave(this.addFromData).then((res) => {
          if (res.code == 1) {
            this.getSurveyQuestionList();
            this.visible = false;
          }
          this.confirmLoading = false;
        });
      } else {
        try {
          let list = this.addFromData.survey_option;
          list.forEach((el) => {
            if (el == "") {
              throw new Error("finish");
            }
          });
        } catch (e) {
          if (e.message == "finish") {
            this.$message.error("选项不能为空");
            return false;
          }
        }
        let count = 0;
        this.addFromData.survey_ratio.forEach((item) => {
          count += item;
        });
        if (count > 100) {
          this.$message.error("权重之和在0-100之间");
          return false;
        }
        this.confirmLoading = true;
        surveyQuestionSave(this.addFromData).then((res) => {
          if (res.code == 1) {
            this.getSurveyQuestionList();
            this.visible = false;
          }
          this.confirmLoading = false;
        });
      }
    },
    handleCancel(e) {
      this.visible = false;
    },
    editMenu(record) {
      this.modalTitle = "修改角色信息";
      this.typeFlag = true;
      this.addFromData = {
        id: record.id,
        survey_id: this.$route.query.id,
        question_title: record.question_title,
        question_type: record.question_type,
        survey_option: [],
        survey_ratio: [],
      };
      if (record.ops.length > 0) {
        record.ops.forEach((item) => {
          this.addFromData.survey_option.push(item.option_title);
          this.addFromData.survey_ratio.push(item.ratio);
        });
      } else {
        this.addFromData.survey_option = [""];
        this.addFromData.survey_ratio = [""];
      }
      this.visible = true;
    },
    confirm(rec) {
      surveyQuestionDel({ id: rec.id }).then((res) => {
        if (res.code == 1) {
          this.$message.success("删除成功");
          this.getSurveyQuestionList();
        }
      });
    },
    pageChange(a) {
      this.listParams.page = a.current;
      this.getSurveyQuestionList();
    },
    getSurveyQuestionList() {
      surveyQuestionList(this.listParams).then((res) => {
        if (res.data.list.length == 0 && this.listParams.page > 1) {
          this.listParams.page--;
          this.getSurveyQuestionList();
        } else {
          this.data = res.data.list;
          this.count = res.data.count;
        }
      });
    },
    onSearch(value) {
      this.listParams.page = 1;
      this.getSurveyQuestionList();
    },
  },
};
</script>

<style lang="less" scoped>
.roleInfo {
  .actionBar {
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
  }
}
</style>
